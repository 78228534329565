.metric-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 32px;

    div {
        display: flex;
        align-items: flex-end;
    }

    .value {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 55px;
        text-align: center;
        text-shadow: 0px 0px 15px rgb(var(--black));
    }

    .label {
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-shadow: 0px 0px 15px rgb(var(--black));
    }

    .unit {
        font-size: 10px;
        padding-bottom: 4px;
        letter-spacing: 0.1em;
        text-shadow: 0px 0px 15px rgb(var(--black));
    }

    .battery-metric-font {
        text-shadow: 0px 0px 16px rgba(var(--alert), 0.5);
        color: rgb(var(--alert));
    }
}
