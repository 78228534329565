.scene-preview-header-container {
    display: flex;

    label {
        cursor: pointer;
        width: 100%;
        text-align: left;
        font-family: "Alternox";
        font-weight: 600;
        font-size: 17px;
        text-shadow: 0px 0px 15px rgb(var(--black));
        display: flex;
        flex: 1;
    }

    .centered-change-view-label {
        justify-content: center;
    }

    img {
        width: 14px;
        height: 16px;
        display: flex;
        align-self: flex-end;
        cursor: pointer;
    }

    .icon-container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .subtask-label {
        font-family: "Alternox";
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        flex: 1;
    }
}
