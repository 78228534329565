  /* ------------------ Digital Fit Css Start Here ---------------------- */

  .digitalfit-modal {

    background-color: #14181B;
    top: 150px;
    font-family: 'Alternox-ExLight';
  }

  .digitalfit-modal span {

    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 2px;
  }

  .digitalfit-modal h6 {
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 3px;
  }

  .digitalfit-modal .digital_buttons {
    text-align: center;
  }

  .digitalfit-modal .digital_buttons .end_session{
    text-align: center;
    font-size: 13px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Alternox-ExLight';
    letter-spacing: 3px;
    border: 1px solid #01426A;
    padding: 15px 90px;
    left: 20px;
    text-transform: uppercase;
  }

  .digitalfit-modal .digital_buttons .continue_session{
    text-align: center;
    font-size: 13px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Alternox-ExLight';
    letter-spacing: 3px;
    border: 1px solid #01426A;
    padding: 15px 110px;
    left: 20px;
    text-transform: uppercase;
  }

  .inner_digital_modal {
    position: relative;
    padding: 10px 40px;
    color: #FFFFFF;
    span.corner-top-left,
    span.corner-top-right,
    span.corner-bottom-left,
    span.corner-bottom-right {
      display: block;
      content: "";
      width: 70px;
      height: 70px;
      position: absolute;
      background: transparent;
    }
  
    span.corner-top-left {
        top: -4px;
        left: -4px;
        border-top: 0.3rem solid #01426A;
        border-left: 0.3rem solid #01426A;
        border-top-left-radius: 5px; 
    }
  
    span.corner-top-right {
      top: -4px;
      right: -4px;
      border-top: 0.3rem solid #01426A;
      border-right: 0.3rem solid #01426A;
      border-top-right-radius: 5px;
    }
  
    span.corner-bottom-left {
      bottom: -4px;
      left: -4px;
      border-bottom: 0.3rem solid #01426A;
      border-left: 0.3rem solid #01426A;
      border-bottom-left-radius: 5px;
    }
  
    span.corner-bottom-right {
      bottom: -4px;
      right: -4px;
      border-bottom: 0.3rem solid #01426A;
      border-right: 0.3rem solid #01426A;
      border-bottom-right-radius: 5px;
    }
  }


  .digital-fit .create-digital {
    color: #FFFFFF; 
     font-size: 16px;
     font-family: 'Alternox-Light';
  }

  .digital-fit .upload-digital {
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Alternox-Light';
  }

  .digital-fit .digital-card {
    background-color: #191F24;
    border-radius: 10px;
    max-width: 120%;
    width: 120%;
  }

  .toggle-label {
    position: relative;
    display: block;
    width: 345px;
    font-size: 14px;
    font-family: 'Alternox-ExLight';
    height: 50px;
    margin-top: 8px;
  }
  .toggle-label input[type=checkbox] { 
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .toggle-label input[type=checkbox]:checked+.back {
    background: #10202b; /*green*/
  }
  
  .toggle-label input[type=checkbox]+.back .toggle {
    display: block;
    position: absolute;
    content: ' ';
    width: 50%; 
    height: 100%;
    transition: margin 150ms linear;
    background: #10202b !important;
    border: 1px solid #01426A;
    border-radius: 10px !important;
    border-radius: 0;
  }
  .toggle-label input[type=checkbox]:checked+.back .toggle {
    margin-left: 170px;
  }
  .toggle-label .label {
    display: block;
    position: absolute;
    width: 50%;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
  }
  .toggle-label .label.on { left: 0px; }
  .toggle-label .label.off { right: 0px; }
  
  .toggle-label input[type=checkbox]:checked+.back .label.on {
    color: #FFFFFF;
  }
  .toggle-label input[type=checkbox]+.back .label.off {
    color: #FFFFFF;
  }
  .toggle-label input[type=checkbox]:checked+.back .label.off {
    color: #FFFFFF;
  }

  .digital-fit .table-data {
    height: 50vh;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    width: 100%
  }

  .digital-fit .table-data::-webkit-scrollbar {
    width: 5px;
}

.digital-fit .table-data::-webkit-scrollbar-thumb {
    background-color: #01426A;
    
}

.digital-fit .table-data::-webkit-scrollbar-track {
    background-color: #14181B;
}

.digital-fit .table-data::-webkit-scrollbar-corner {
    background-color: #14181B; 
  }

  .digital-table.table > thead > tr > th,
  .digital-table.table > tbody > tr > th,
  .digital-table.table > tfoot > tr > th,
  .digital-table.table > thead > tr > td,
  .digital-table.table > tbody > tr > td,
  .digital-table.table > tfoot > tr > td {
    border: none !important;
    text-align: justify;
    color: #FFFFFF !important;
    font-family: 'Alternox-ExLight';
    font-size: 14px;
  
  }

  .digital-fit .table-data .xmm {

    margin-left: -0.5rem !important;
  }

  input{
    -webkit-appearance: none;
    /* width: 12rem; */
    background:transparent;
  }
  
  /* Track Styles  */
  /* chrome, safari, edge, firefox */
  input::-webkit-slider-runnable-track{
    height:1px;
    border-radius:20px;
  }
  
  input::-moz-range-track{
    height:1px;
    border-radius:20px;
  }
  
  input::-webkit-slider-runnable-track {
    background:#01426A;
  }
  
  input::-moz-range-thumb:hover{
    cursor:grab;
  }
  input::-webkit-slider-thumb:hover{
    cursor:grab;
  }
  
  .digital-fit .body-icons h6 {
    font-family: 'Alternox-EXLight';
  }
  
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height:9px;
    width:9px;
    border-radius:50%;
    border:none;
    background-color:#01426a;
    box-shadow:inset .5px .5px 1px #57575780; 
    transform:translateY(-5px);
  }

  .digital-button {
    background: #101f2a !important;
    padding:15px 65px !important;
    border: 1px solid #01426A;
    border-radius: 10px !important;
    font-family: 'Alternox-ExLight';
    font-size: 12px !important;
    letter-spacing: 2px;
  
  }

  .human-icons .gif-icons {
    cursor: pointer;
  }
  

  @media(min-aspect-ratio: 16/9) {
    .human-video {
      width: 100%;
      height: auto;
    }
  }

  @media (max-aspect-ratio: 16/9) {
    .human-video {

      width: auto;
      height: 100%;
    }
  }

  .digital-fit .human-icons img {
    width: 100px !important;
    max-width: 100px !important;
    height: 50px !important;
    margin-top: 45px;
}

  .digital-fit .human-icons h6 {

    font-size: 10px !important;

  }

  @media screen and (min-width: 320px) and (max-width: 425px) {


    .digital-fit .digital-card {
      margin-left: -50px;
    }

    .toggle-label {
      position: relative;
      display: block;
      width: 345px;
      margin-left: -20px;
      font-size: 14px;
      font-family: 'Alternox-ExLight';
      height: 50px;
      margin-top: 8px;
    }

    .digital-fit .table-data {
      height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      width: 150%
    }

    .digital-fit .table-data span {
      font-size: 10px;
    }


    @media(min-aspect-ratio: 16/9) {
      .human-video {
        width: 20%;
        height: auto;
      }
    }
  
    @media (max-aspect-ratio: 16/9) {
      .human-video {
        width: auto;
        height: 20%;
      }
    }

  }

  @media screen and (min-width: 425px) and (max-width: 625px) {

    @media(min-aspect-ratio: 16/9) {
      .human-video {
        width: 20%;
        height: auto;
      }
    }
  
    @media (max-aspect-ratio: 16/9) {
      .human-video {
  
        width: auto;
        height: 20%;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {

    .digital-fit .create-digital {
       font-size: 12px;
       font-family: 'Alternox-ExLight';
    }

    .digital-fit .upload-digital {
      font-size: 12px;
      font-family: 'Alternox-Light';
    }
    /* .digital-fit .upload-digital span {
      color: #FFFFFF !important;
      font-family: 'Alternox-ExLight'!important ;
    } */

    .toggle-label input[type=checkbox]+.back .toggle {
      width: 35%; 
      height: 90%;
      transition: margin 150ms linear;
    }

    .toggle-label .on{
      width: 29%;
      font-size: 10px;
    }

    .toggle-label .off{
      width: 95% !important;
      max-width: 95%;
      font-size: 10px;
    }

    .toggle-label input[type=checkbox]:checked+.back .toggle {
      margin-left: 120px;
    }

    .digital-fit .digital-card {
      background-color: #191F24;
      border-radius: 10px;
      max-width: 150%;
      width: 150%;
    }

    .digital-fit .table-data {
      height: 50vh;
      overflow-x: auto;
      overflow-y: auto;
      width: 120%;
    }

    .digital-fit .table-data span{

      font-size: 10PX;
    }

    .digital-fit .table-data .xmm {
      font-size: 10px;
      text-wrap: nowrap;
    }

    .digital-button {
      padding:15px 45px !important;
      margin-left: -80px;
    }

    @media(min-aspect-ratio: 16/9) {
      .human-video {
        width: 20%;
        height: auto;
      }
    }
  
    @media (max-aspect-ratio: 16/9) {
      .human-video {
  
        width: auto;
        height: 20%;
      }
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {


    .digital-fit .digital-card {
      max-width: 149%;
      width: 149%;
    }

    .toggle-label input[type=checkbox]+.back .toggle {
      width: 35%; 
      height: 90%;
      transition: margin 150ms linear;
    }

    .toggle-label .on{
      width: 29%;
      font-size: 12px;
    }

    .toggle-label .off{
      width: 95% !important;
      max-width: 95%;
      font-size: 12px;
    }

    .toggle-label input[type=checkbox]:checked+.back .toggle {
      margin-left: 120px;
    }
    .digital-fit .table-data {
      height: 50vh;
      overflow-x: auto;
      overflow-y: auto;
      width: 120%;
    }

    .digital-fit .table-data span{

      font-size: 10PX;
    }

    .digital-fit .table-data .xmm {
      font-size: 10px;
      text-wrap: nowrap;
    }

    .digital-button {
      padding:15px 45px !important;
      margin-left: -80px;
    }
  }

  @media screen and (min-width: 1365px) and (max-width: 1440px) {

    .digital-fit .digital-card {
      max-width: 149% !important;
      width: 149% !important;
    }

    .toggle-label input[type=checkbox]+.back .toggle {
      width: 45%; 
      height: 100%;
      transition: margin 150ms linear;
    }

    .toggle-label .on{
      width: 40%;
      font-size: 14px;
    }

    .toggle-label .off{
      width: 50% !important;
      max-width: 50%;
      font-size: 14px;
    }

    .toggle-label input[type=checkbox]:checked+.back .toggle {
      margin-left: 180px;
    }
    input{
      -webkit-appearance: none;
      width: 14rem;
      background:transparent;
    }

    .digital-fit .table-data {
      height: 50vh;
      overflow-x: auto;
      overflow-y: auto;
      width: 120%;
    }

    .digital-fit .table-data span{

      font-size: 10PX;
    }

    .digital-fit .table-data .xmm {
      font-size: 10px;
      text-wrap: nowrap;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1600px) {


    .toggle-label {
      position: relative;
      display: block;
      width: 345px;
      margin-left: -20px;
      font-size: 14px;
      font-family: 'Alternox-ExLight';
      height: 50px;
      margin-top: 8px;
    }
    .digital-fit .table-data {
      height: 50vh;
      overflow-x: auto;
      overflow-y: auto;
      width: 120%;
    }

    .digital-fit .table-data span{

      font-size: 10PX;
    }

    .digital-fit .table-data .xmm {
      font-size: 10px;
      text-wrap: nowrap;
    }
  }
  
  @media screen and (min-width: 1600px) and (max-width: 1920px) {

    .digital-fit .table-data {
      height: 50vh;
      overflow-x: auto;
      overflow-y: auto;
      width: 120%;
    }

    input{
      -webkit-appearance: none;
      width: 16rem;
      background:transparent;
    }
    .digital-fit .table-data span{

      font-size: 10PX;
    }

    .digital-fit .table-data .xmm {
      font-size: 10px;
      text-wrap: nowrap;
    }
  }
  
/* ------------------ Digital Fit Css Ends Here ---------------------- */