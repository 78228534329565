.gauge {
  
}

.gauge-back {
  stroke-opacity: 0.3;
}

.gauge-value {
  /* Adjust this stroke-dasharray to represent the gauge value (first number) */
  transition: stroke-dasharray 0.3s ease-in-out;
}

.gauge-label,
.gauge-value-text {
  font-weight: bold;
}





/* Base styles for the container */
.progress-bar  {
  background-color: #000;
  border-radius: 50px;
}
.progress-bar-container {
  background-color: #1C1C1E; /* Dark background */
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px; /* Space between progress bars */
  color: #FFFFFF; /* Light text */
}

/* Label styles */
.label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; /* Space between label and progress bar */
}

/* Progress bar base styles */
.progress-bar progress {
  width: 100%;
  -webkit-appearance: none; /* Override default appearance */
  appearance: none; /* Override default appearance */
  border-radius: 10px; /* Rounded corners */
  height: 10px; /* Set a fixed height for all progress bars */
  overflow: hidden; /* Hide the overflow to respect border-radius */
}

/* Customized styles for each progress bar's value */
.progress.inertialvelocity::-webkit-progress-value,
.progress.altitude::-webkit-progress-value,
.progress.apogee::-webkit-progress-value,
.progress.perigee::-webkit-progress-value,
.progress.inclination::-webkit-progress-value,
.progress.rangetoiss::-webkit-progress-value {
  background-color: #3D72B4;
  border-radius: 10px; /* Rounded corners */
}

/* Styles for the background of the progress bar */
.progress.inertialvelocity::-webkit-progress-bar,
.progress.altitude::-webkit-progress-bar,
.progress.apogee::-webkit-progress-bar,
.progress.perigee::-webkit-progress-bar,
.progress.inclination::-webkit-progress-bar,
.progress.rangetoiss::-webkit-progress-bar {
  background-color: #0D0D0D;
}

/* For other browsers like Firefox */
.progress-bar progress::-moz-progress-bar {
  background-color: #3D72B4;
  border-radius: 10px; /* Rounded corners */
}

/* Make sure to handle the overflow of the label text if it's too long */
.label span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.connections-data {
  /* Set a fixed height for the container. Adjust as needed. */
  height: 300px; 

  /* Only vertical scrolling */
  overflow-y: auto; 
  overflow-x: hidden; /* This prevents horizontal scrolling */

  /* Apply box-sizing property */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

/* Custom styles for the vertical scrollbar */
.connections-data::-webkit-scrollbar {
  width: 8px; /* Decrease the width of the scrollbar */
}

.connections-data::-webkit-scrollbar-track {
  background: rgba(1, 66, 106, 0.2); /* Custom scrollbar track color */
}

.connections-data::-webkit-scrollbar-thumb {
  background: rgba(1, 66, 106, 0.5); /* Custom scrollbar thumb color */
  border-radius: 4px; /* Optional: adds rounded corners to the scrollbar thumb */
}

/* Make the scrollbar thumb slightly darker on hover for better UX */
.connections-data::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 66, 106, 0.7);
}

/* For Firefox */
.connections-data {
  scrollbar-color: rgba(1, 66, 106, 0.5) rgba(1, 66, 106, 0.2);
  scrollbar-width: thin; /* Makes the scrollbar thinner in Firefox */
}


.suit-diagnostics {
  background: #10202b !important;
  border: 1px solid #4472c4 !important;
  border-radius: 10px !important;
}

.diagnostic-card {
  height: 16vh;
  min-height: 16vh;
}
.diagnostics-image {
  position: relative;
  width: 300px;
  left: 50px;
  bottom: 52px;
}



.zoom-effect {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
  display: block;
}

.zoom-effect:hover {
  transform: scale(1.5); /* (150% zoom - Feel free to change it) */
}


#motion-stability-chart .user-select-none.svg-container {
  all: unset; /* Remove all applied styles */
  position: static !important;
  width: 100% !important;
  height: auto !important;
}
