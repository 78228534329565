.tab-switch-wrapper .switch-label{
    font-family: 'Alternox';
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 50%;
    justify-content: center;
    color: rgb(var(--primary-text-color));
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    padding: 15px 5px ;
    background-color: var(--ui-dark-1);
    color: rgba(255,255,255, 0.2);
    border: 1px solid var(--ui-dark-1)
}

.tab-switch-wrapper{
    display: flex;
    background-color: var(--ui-dark-1);
    border-radius: 16px;
    padding: 4px;
    min-width: 598px;
}

.tab-switch-wrapper:first-child{
    margin-right: 5px;
    }

.tab-switch-wrapper .focused-switch{
    color: rgb(var(--primary-text-color));
    background-color: rgba(1, 66, 106, 0.2);
    border-radius: 12px;
    border: 1px solid rgb(var(--metakosmos-blue));
}