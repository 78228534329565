.scene-preview-panel {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
    display: flex;
    flex: 1;

    & > div {
        display: flex;
        background-color: #14181B;
        flex-direction: column;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
        padding: 15px;
        flex: 1;

        .Tasks-list {
            width: 25%;
            height: 100%;
            margin-top: 15px;
        }

        .select-button-container {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;

            button {
                background: none;
                color: rgb(255,255,255);
                border: none;
                cursor: pointer;
                font-family: "Alternox";
                font-weight: 600;
                font-size: 19px;
                text-transform: uppercase;

                &[disabled] {
                    cursor: not-allowed;
                }
            }
        }
        .panel-footer {
            display: flex;
            flex: 1;
            align-items: flex-end;
            justify-content: flex-start;
        }
    }

    .fullScreen {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        border-radius: unset;
        padding: 32px 42px;
        background-size: cover;
        z-index: 1;
    }

    .bg-overlay {
        background-color: rgba(0,0,0,0.5);
        background-blend-mode: overlay;
    }
}
