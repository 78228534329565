/* .slick-next {
    right: 20px !important;
}

.slick-prev {
    left: 23px !important;
}

.slick-track
{
    position: relative;
    top: 0;
    left: 6% !important;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.planet {
    color: #FFFFFF !important;
    font-size: 16px !important;
}

.slick-slide .slick-cloned {

    width: 240px !important;
} */

/* Add this CSS to your stylesheet */
.carousel .control-dots {
    bottom: 10px; /* Adjust the position of the dots */
  }
  
  .carousel .control-dots .dot {
    width: 50px; /* Adjust the size of the dots */
    height: 50px;
    margin: 0 5px; /* Adjust the spacing between the dots */
  }
  
  .carousel .control-dots .dot.selected {
    background-color: #007bff; /* Change the color of the selected dot */
  }

  .carousel-slide {
    text-align: start;
  }

  /* Custom Previous Button */
.custom-prev-button {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.custom-prev-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Custom Next Button */
.custom-next-button {
  position: absolute;
  top: 50%;
  right: 48px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.custom-next-button:hover {
  background: rgba(0, 0, 0, 0.8);
}
