@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.svg-container {
  position: relative;
}
#speed {
  color: white;
  font-size: 36px;
  line-height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  bottom: 25px;
  z-index: 1;
}
#meter-bg-bar {
  animation: dash 4s linear forwards infinite;
  stroke-dasharray: 615;
}
@keyframes dash {
  0%,
  100% {
    stroke-dashoffset: 615;
  }
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 307.5;
  }
  75% {
    stroke-dashoffset: 0;
  }
}