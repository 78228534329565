.card-content {
    background: var(--ui-dark-1);
    border-radius: 16px;
    position: relative;

    &:before,
    &:after,
    & > :first-child:before,
    & > :first-child:after {
        position: absolute;
        content: " ";
        width: 80px;
        height: 80px;
        border: 5px solid rgb(var(--metakosmos-blue));
        filter: drop-shadow(0 0 0.4rem rgb(var(--metakosmos-blue)));
    }

    &:before {
        top: calc(0px + -4px);
        left: calc(0px + -4px);
        border-width: 5px 0 0 5px;
        border-top-left-radius: 20px;
    }

    &:after {
        top: calc(0px + -4px);
        right: calc(0px + -4px);
        border-width: 5px 5px 0 0;
        border-top-right-radius: 20px;
    }

    & > :first-child:before {
        bottom: calc(0px + -4px);
        right: calc(0px + -4px);
        border-width: 0 5px 5px 0;
        border-bottom-right-radius: 20px;
    }

    & > :first-child:after {
        bottom: calc(0px + -4px);
        left: calc(0px + -4px);
        border-width: 0 0 5px 5px;
        border-bottom-left-radius: 20px;
    }
}
