.app-header {
    background-color: var(--ui-dark-1);
    display: grid;
    grid-template-columns: 1.3fr 1.4fr 1.3fr;
    align-items: center;
    padding: 16px 0 17px 0;
    z-index: 1;
}

.menu-heading {
    display: flex;
    align-items: center;
}

.selector-container {
    display: flex;
    justify-content: space-around;
}

.selector-container select {
    border: none;
    color: rgb(var(--primary-text-color));
    background: var(--ui-dark-1);
    cursor: pointer;
    font-family: "Alternox";
    font-weight: 200;
    font-size: 16px;
    outline: none;
    position: absolute;
    opacity: 0;
}

.selector {
    padding-right: 15px;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.selector-label label {
    font-family: "Alternox";
    font-weight: 200;
    font-size: 16px;
    padding-right: 12px;
}

.selector-container option {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 100;
}

.app-header .menu-item {
    padding: 0 24px;
}

.app-header .logo {
    height: 32px;
    width: 100px;
}

.app-header .vl {
    height: 33px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.app-header .title {
    font-family: "Alternox";
    font-size: 23px;
    padding: 0 24px;
    white-space: nowrap;
}