.menu-list {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    .ddcontainer {
        position: unset !important;
        cursor: default !important;
        display: unset !important;

        .ddcontainersource{
            visibility: visible !important;
        }
    }

    li {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        text-align: left;
        opacity: 0.4;

        button {
            background: none;
            color: inherit;
            border: none;
            width: 100%;
            outline: inherit;
            font-family: "Alternox";
            font-weight: lighter;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 0px 15px;
            margin-top: 24px;
            text-align: left;

            &:disabled {
                cursor: not-allowed;
            }

            img {
                width: 25px;
                height: 25px;
                margin-left: 10px;
                border-radius: 10px;
            }
        }
    }

    .active {
        opacity: 1;

        button {
            font-weight: 600;
            border-color: rgb(var(--metakosmos-blue));
            border-style: solid;
            border-width: 0px 4px;
        }
    }

    .disabled {
        opacity: 0.2;
    }
}
