/* Container styling */
.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background-color: #1f1f1f; /* Dark background */
    border-radius: 8px;
    /* width: 215px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  /* Wrapper for each dropdown */
  .dropdown-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* Label styling */
  .dropdown-label {
    font-size: 14px;
    font-weight: bold;
    color: #fff; /* White text */
  }
  
  /* Dropdown styling */
  .dropdown {
    background-color: #2c2c2c; /* Slightly lighter than the background */
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 2px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
  }
  
  .dropdown:hover {
    background-color: #3b3b3b; /* Highlight effect on hover */
  }
  
  .dropdown:focus {
    border: 1px solid #007bff; /* Focus outline */
  }
  
  /* Button styling */
  .update-button {
    padding: 10px 15px;
    background-color: #007bff; /* Blue button */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .update-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .update-button:active {
    transform: scale(0.95); /* Slight shrink on click */
  }
  