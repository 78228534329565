
.echart-graph {
  background: #1E2429 !important;
  border: 1px solid rgb(1, 66, 106);
  border-radius: 16px;
  padding: 15px;
}


.tooltip-inner {
  background-color: #6c757d !important; /* Custom background color */
  font-size: 14px !important;  /* Custom font size */
  padding: 10px 15px;  /* Optional: adjust padding */
  border-radius: 5px;  /* Optional: rounded corners */
  color: white;  /* Text color */
}

/* Optional: You can also customize the tooltip arrow */
.tooltip-arrow {
  border-top-color: #6c757d !important;  /* Arrow color matching the tooltip background */
}

.modal-container {
  max-width: 100%; /* Allow the modal to expand fully */
  padding: 0 20px; /* Add padding on the sides */
}
