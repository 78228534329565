.surface-chart-container {
    padding: 20px;
    background: linear-gradient(145deg,#1e2429, #2A2A2A);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid #333;
    max-width: 800px;
    margin: 20px auto;
    position: relative;
    top: -46px;
  }
  
  .surface-chart-container .echarts-for-react div {
    outline: none;
  }
  
  .surface-chart-container:hover {
    box-shadow: 0px 8px 20px rgba(0, 229, 255, 0.7);
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
  }
  