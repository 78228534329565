:root {
  --darkbg: #251d29;
  --darkt: #ffd1f7;
  --lightbg: #fff;
  --lightt: #d43370;

  --toggleHeight: 16em;
  --toggleWidth: 30em;
  --toggleBtnRadius: 10em;

  --bgColor--night: #423966;
  --toggleBtn-bgColor--night: var(--bgColor--night);
  --mooncolor: #d9fbff;
  --bgColor--day: #9ee3fb;
  --toggleBtn-bgColor--day: var(--bgColor--day);
}


.tdnn {
  margin: 0 auto;
  font-size: 20%;
  margin-top: 10em;
  z-index: 1000;
  position: relative;
  height: var(--toggleHeight);
  width: var(--toggleWidth);
  border-radius: var(--toggleHeight);
  transition: all 500ms ease-in-out;
  background: var(--bgColor--night);
}

.day {
  background: #ffbf71;
}

.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: var(--toggleBtnRadius);
  height: var(--toggleBtnRadius);
  background: var(--bgColor--night);
  box-shadow: 3em 2.5em 0 0em var(--mooncolor) inset, rgba(255, 255, 255, 0.1) 0em -7em 0 -4.5em, rgba(255, 255, 255, 0.1) 3em 7em 0 -4.5em, rgba(255, 255, 255, 0.1) 2em 13em 0 -4em, rgba(255, 255, 255, 0.1) 6em 2em 0 -4.1em, rgba(255, 255, 255, 0.1) 8em 8em 0 -4.5em, rgba(255, 255, 255, 0.1) 6em 13em 0 -4.5em, rgba(255, 255, 255, 0.1) -4em 7em 0 -4.5em, rgba(255, 255, 255, 0.1) -1em 10em 0 -4.5em;
}

.sun {
  top: 4.5em;
  left: 18em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #fff;
  box-shadow: 3em 3em 0 5em #fff inset, 0 -5em 0 -2.7em #fff, 3.5em -3.5em 0 -3em #fff, 5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff, 0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff, -5em 0 0 -2.7em #fff, -3.5em -3.5em 0 -3em #fff;
}

.toggle-btn {
  position: absolute;
  top: -35px;
  right: 7em;
  z-index: 10000; /* Ensure the toggle button is above the tdnn */
  cursor: pointer;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}



.main-dashboard {
  background-color: #14181B;
  font-family: 'Alternox-ExLight';
}

.main-dashboard span {

  font-size: 24px;
  line-height: 1px;
  text-align: center;
  color: #FFFFFF;
  /* letter-spacing: 1px; */
}

.main-dashboard span {
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
  letter-spacing: 1px;
}

.scichart__legend > div:first-child {
  display: block;
  align-self: flex-start;
  width: fit-content;
  pointer-events: auto;
  margin-left: 1px !important;
  margin-top: 2px !important;
  margin-right: 56.015625px;
  margin-bottom: 3px !important;
  padding: 5px;
  border-radius: 3px;
  background-color: #1D2C35FF;
  color: #EEEEEEFF;
}






.inner_dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This spreads out the child elements */
  position: relative;
  padding: 10px 50px;
  color: #FFFFFF;
  min-height: 300px; 
  span.corner-top-left,
  span.corner-top-right,
  span.corner-bottom-left,
  span.corner-bottom-right {
    display: block;
    content: "";
    width: 70px;
    height: 70px;
    position: absolute;
    background: transparent;
  }

  span.corner-top-left {
      top: -4px;
      left: -4px;
      border-top: 0.3rem solid #01426A;
      border-left: 0.3rem solid #01426A;
      filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106)) !important;
      border-top-left-radius: 20px; 
  }

  span.corner-top-right {
    top: -4px;
    right: -4px;
    border-top: 0.3rem solid #01426A;
    border-right: 0.3rem solid #01426A;
    filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106)) !important;
    border-top-right-radius: 20px;
  }

  span.corner-bottom-left {
    bottom: -4px;
    left: -4px;
    border-bottom: 0.3rem solid #01426A;
    border-left: 0.3rem solid #01426A;
    filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106)) !important;
    border-bottom-left-radius: 20px;
  }

  span.corner-bottom-right {
    bottom: -4px;
    right: -4px;
    border-bottom: 0.3rem solid #01426A;
    border-right: 0.3rem solid #01426A;
    filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106)) !important;
    border-bottom-right-radius: 20px;
  }
}

.main-dashboard img {
  width: 100%;
  position: relative;
  height: auto;
}


.main-dashboard .timeline-card {
  width: 100%;
  position: relative;
  height: auto;
}

/* .transperancy_bg {
  background-image: url();
} */

.traffic-lights {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 45px; /* Adjust the width to fit the dots closely together */
}

.traffic-lights .light {
  width: 10px; /* Dot size */
  height: 10px; /* Dot size */
  border-radius: 50%;
  box-shadow: 0 0 8px; /* Adjust for desired blur */
  margin: 0 2px; /* Space between dots */
}

.traffic-lights .red {
  background-color: #ff0000;
}

.traffic-lights .yellow {
  background-color: #ffff00;
}

.green {
  background-color: #00ff00;
}

.main-dashboard .timeline-card button {

  background-color: #494949;
  color: #fff;
  border-radius: 5px;
}

.timeline-card .timecode-display {
  font-size: 3em;
  background-color: #000;
  color: #0f0;
  letter-spacing: 5px;
}


.typing-animation {
  width: fit-content; /* Adjust as necessary */
  animation: typing 4s forwards; /* Use forwards to keep the last frame */
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: "Alternox";
  font-size: 1em; /* Adjust as necessary */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}



/* media queries for time-line card 1024 to 1200  */

@media screen and (min-width: 1023px) and (max-width: 1200px) {
 .timeline-card {
    width: 100%;
    height: 8.5vh !important;
  }
  .timeline-card .card-header {
    width: 100%;
    height: 10px;
  }

  .timeline-card .card-header .traffic-lights {
      position: relative;
      bottom: 15px;
      right: 10px;
  }

  .timeline-card .card-header .time-code {
    position: relative;
    bottom: 25px;
    left: 20px;
    font-size: 10px;
}

.timeline-card button {
  font-size: 5px;
  position: relative;
  bottom: 10px;
}

.timeline-card .timecode-display {
  font-size: 14px;
  background-color: #000;
  position: relative;
  bottom: 20px;
  color: #0f0;
}


}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
 .inner_dashboard {
  min-height: 400px; 
 }
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .inner_dashboard {
    min-height: 450px; 
  }
}


  @media screen and (min-width: 2560px) and (max-width: 3000px) {
    .inner_dashboard {
      min-height: 550px; 
    }
  }
