.metricImgContainer {
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        label {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
        }

        img {
            width: 80%;
        }
    }

    label {
        padding: 10px 0px 0px 10px;
        font-family: "Alternox-Light";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }
}
