.modal-container {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 30px;

    label {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 100;
        font-size: 20px;
        line-height: 28px;
        color: rgb(var(--primary-text-color));
        padding-bottom: 20px;
    }

    .info-bold {
        font-size: 24px;
        font-weight: 300;
        padding-bottom: 30px;
        color: #ffffff;
    }

    button {
        font-family: "Alternox";
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background: rgba(1, 66, 106, 0.2);
        border: 1px solid #01426a;
        border-radius: 8px;
        width: 100%;
        padding: 20px 0 20px 0;
        color: rgb(var(--primary-text-color));
        margin-bottom: 10px;
        cursor: pointer;
    }

    .continue-button {
        background-color: var(--ui-dark-1);
    }
}