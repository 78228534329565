.border{
    background: #14181B;
    border: 1px solid rgb(1, 66, 106) !important;    
    border-radius: 16px;
    padding: 15px;
}


.app {
    display: flex;
    flex-flow: column;
    height: 100vh;
  }
  
  .app .header {
    flex: 0 1 auto;
  }
  
  .app .content {
    flex: 1 1 auto;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: rgb(255, 255, 255);
    height: 100%;
    overflow: hidden;
  }
  
  .app .footer {
    flex: 0 1 40px;
    background-color: #282c34;
    color: white;
    text-align: center;
  }
  
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #C9CFD4 rgba(255, 255, 255, 0.3);
    overflow: auto;
  }
  
  .scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    border-radius: 30px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #C9CFD4;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  .scrollbar::-webkit-scrollbar-button {
    height: 15px;
  }
  
  .scrollbar::-webkit-scrollbar-corner {
    width: 0;
  }
  