.outer-container {
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .suits-preview-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 86px;
        overflow-y: hidden;
        padding: 10px 66px;
    }

    .list-empty-container {
        display: flex;
        flex: 1;
    }

    .list-empty-message {
        text-align: center;
        flex: 1;
    }

    .switch-container {
        display: flex;
        justify-content: center;
        padding-bottom: 42px;
    }

    .switch-label {
        font-family: "Alternox";
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        width: 50%;
        justify-content: center;
        color: rgb(255, 255, 255);
        font-size: 16px;
        line-height: 18px;
        cursor: pointer;
        padding: 15px 5px;
    }
}


.suits-container {
    padding: 43px 0px 43px 0px;
    background-color: #14181B;
    border-radius: 12px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;
    border: 0px;
    height: calc(100vh - 270px);
    cursor: pointer;

    &:focus {
        opacity: 1;
        outline: 0px;
    }

    .suit-label {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        padding: 0px 10px 43px 10px;
        color: rgb(255, 255, 255);
    }

    .suit-img {
        height: 83%;
        aspect-ratio: 2 / 3;
    }

    .select-button {
        background-color: rgba(1, 66, 106, 0.2);
        border: 1px solid rgb(1, 66, 106);
        border-radius: 12px;
        width: 286px;
        max-width: 100%;
    }
}

.my-suit-container {
    height: auto;
}

.unSelected-suits-container {
    opacity: 0.4;
}

#Terra,
#Aqua,
#Vyom,
#customSuit {
    position: relative;
}

#Terra:before,
#Terra:after,
#Terra > :first-child:before,
#Terra > :first-child:after,
#Aqua:before,
#Aqua:after,
#Aqua > :first-child:before,
#Aqua > :first-child:after,
#Vyom:before,
#Vyom:after,
#Vyom > :first-child:before,
#Vyom > :first-child:after,
#customSuit:before,
#customSuit:after,
#customSuit > :first-child:before,
#customSuit > :first-child:after {
    position: absolute;
    content: " ";
    width: 10%;
    height: 8%;
}

#Terra:before,
#Terra:after,
#Terra > :first-child:before,
#Terra > :first-child:after {
    border: 4px solid #95C355;
    filter: drop-shadow(0 0 0.4rem rgba(149, 195, 85, 2.5));
}

#Aqua:before,
#Aqua:after,
#Aqua > :first-child:before,
#Aqua > :first-child:after {
    border: 4px solid #1F14F6;
    filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106));
}

#Vyom:before,
#Vyom:after,
#Vyom > :first-child:before,
#Vyom > :first-child:after {
    border: 4px solid #7C3CAA;
    filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106));
}

#customSuit:before,
#customSuit:after,
#customSuit > :first-child:before,
#customSuit > :first-child:after {
    border: 4px solid #95C355;
    filter: drop-shadow(0 0 0.4rem rgba(149, 195, 85, 2.5));
}

#Terra:before,
#Aqua:before,
#Vyom:before,
#customSuit:before {
    top: -4px;
    left: -4px;
    border-width: 4px 0 0 4px;
    border-top-left-radius: 16px;
}

#Terra:after,
#Aqua:after,
#Vyom:after,
#customSuit:after {
    top: -4px;
    right: -4px;
    border-width: 4px 4px 0 0;
    border-top-right-radius: 16px;
}

#Terra > :first-child:before,
#Aqua > :first-child:before,
#Vyom > :first-child:before,
#customSuit > :first-child:before {
    bottom: -4px;
    right: -4px;
    border-width: 0 4px 4px 0;
    border-bottom-right-radius: 16px;
}

#Terra > :first-child:after,
#Aqua > :first-child:after,
#Vyom > :first-child:after,
#customSuit > :first-child:after {
    bottom: -4px;
    left: -4px;
    border-width: 0 0 4px 4px;
    border-bottom-left-radius: 16px;
}

@media only screen and (max-width: 1024px) {
    #Terra:before,
    #Terra:after,
    #Terra > :first-child:before,
    #Terra > :first-child:after,
    #Aqua:before,
    #Aqua:after,
    #Aqua > :first-child:before,
    #Aqua > :first-child:after,
    #Vyom:before,
    #Vyom:after,
    #Vyom > :first-child:before,
    #Vyom > :first-child:after {
        position: absolute;
        content: " ";
        width: 13%;
        height: 8%;
    }
}


.tab-switch-wrapper .switch-label{
    font-family: 'Alternox';
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 50%;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    padding: 15px 5px ;
    background-color: #14181B;
    color: rgba(255,255,255, 0.2);
    border: 1px solid #14181B
}

.tab-switch-wrapper{
    display: flex;
    background-color: #14181B;
    border-radius: 26px;
    padding: 10px;
    margin-top: 50px;
    min-width: 598px;
}

.tab-switch-wrapper:first-child{
    margin-right: 5px;
    }

.tab-switch-wrapper .focused-switch{
    color: rgb(255, 255, 255);
    background-color: rgba(1, 66, 106, 0.2);
    border-radius: 12px;
    border: 1px solid rgb(1, 66, 106);
}

// modal css

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(var(0, 0, 0), 0.6);
    display: none;
  }
  
  .modal.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .modal-header {
      font-family: 'Alternox-Light';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;    
      
      letter-spacing: 0.1em;
      text-transform: uppercase;
  }
  
  .modal-main {  
    width: 539px;
    height: auto;    
    background: #14181B;  
  }
  
  .modal-main {position:relative}
  .modal-main:before, .modal-main:after, .modal-main>:first-child:before, .modal-main>:first-child:after {
      position:absolute; 
      content:' ';
      width: 80px; height: 80px;
      border: 5px solid rgb(1, 66, 106);    
      filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106));
  }
  .modal-main:before {
      top:-4px;
      left:-4px;
      border-width: 5px 0 0 5px;
      border-top-left-radius: 20px;    
  }
  .modal-main:after {
      top:-4px;
      right:-4px;
      border-width: 5px 5px 0 0;
      border-top-right-radius: 20px;   
  
  }
  
  .modal-main>:first-child:before {
      bottom:-4px;
      right:-4px;
      border-width: 0 5px 5px 0;
      border-bottom-right-radius: 20px;    
  
  }
  .modal-main>:first-child:after {
      bottom:-4px;
      left:-4px;
      border-width: 0 0 5px 5px;
      border-bottom-left-radius: 20px;
  }

  // ende session scss

  .modal-container {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 30px;

    label {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 100;
        font-size: 20px;
        line-height: 28px;
        color: rgb(255, 255, 255);
        padding-bottom: 20px;
    }

    .info-bold {
        font-size: 24px;
        font-weight: 300;
        padding-bottom: 30px;
        color: #ffffff;
    }

    button {
        font-family: "Alternox";
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background: rgba(1, 66, 106, 0.2);
        border: 1px solid #01426a;
        border-radius: 8px;
        width: 100%;
        padding: 20px 0 20px 0;
        color: rgb(255, 255, 255);
        margin-bottom: 10px;
        cursor: pointer;
    }

    .continue-button {
        background-color: #14181B;
    }
}