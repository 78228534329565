.save-As-modal {
    padding: 10px;

    input {
        background: transparent;
        border: none;
        color: var(--primary-text-color);
        border-bottom: 1px solid rgb(1, 66, 106);
        text-align: center;
        margin-bottom: 20px;
        outline: none;
        font-size: 18px;
    }

    .validation {
        display: flex;
        justify-content: center;
        margin: 5px;

        label {
            color: red;
            font-family: "Alternox";
            font-size: 16px;
            padding-bottom: 20px;
        }
    }

    div {
        display: flex;
        justify-content: space-between;
    }

    button {
        padding: 15px;
        font-family: "Alternox";
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background-color: rgba(1, 66, 106, 0.2);
        border-radius: 16px;
        border: 1px solid rgb(1, 66, 106);
        width: 49%;
        color: rgb(255, 255, 255);
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .cancel-button {
        background-color: #14181B;
    }

    .modal-main:before,
    .modal-main:after,
    .modal-main > :first-child:before,
    .modal-main > :first-child:after {
        width: 45px;
        height: 45px;
    }
}
