/* ----------------------  Metrics  Component Css Start Here----------------------------- */

.metric_button {
  padding: 20px;
}

.top-left img {
  width: 80px !important;
  height: auto !important;
  margin-bottom: -10% !important;
  margin-left: 15% !important;
  
}

.topbar-table.table > thead > tr > th,
.topbar-table.table > tbody > tr > th,
.topbar-table.table > tfoot > tr > th,
.topbar-table.table > thead > tr > td,
.topbar-table.table > tbody > tr > td,
.topbar-table.table > tfoot > tr > td {
  border: none !important;
}

.topbar-table.table > tbody > tr:first-child > td:not(:first-child) {
  padding-left: 185px;
  white-space: nowrap;
}

.modal.data-extraction {
  left: 490px !important;
  /* top: 230px !important; */

}


@media screen and (min-width: 320px) and (max-width: 768px) {

  .metric_button {
    padding: 10px !important;
  }

  .metric_button:first-child {
    margin-left: 130% !important;
  }

  

  .top-left img {
    width: 60px !important;
    height: auto !important;
    margin-bottom: -44% !important;
    
  }

  .modal.data-extraction {
    left: 0px !important;
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .metric_button {
    padding: 6px !important;
  }

  .metric_button:first-child {
    margin-left: -25% !important;
  }

  .top-left img {
    width: 60px !important;
    height: auto !important;
    margin-bottom: -10% !important;
    
  }

  .modal.data-extraction {
    left: 0px !important;
  }

}


@media screen and (min-width: 1024px) and (max-width: 1440px) {

  .metric_button {
    font-size: 10px !important;
    padding: 7px !important;
  }
  .metric_button:first-child {
    margin-left: -7% !important;
  }
  

  .top-left img {
    width: 70px !important;
    height: auto !important;
    margin-bottom: -8px !important;
  }

  .topbar-table.table > tbody > tr > td {
    
    font-size: 10px !important;
  }

  .topbar-table.table > tbody > tr:first-child > td {
    padding-left: 1px !important;
  }

  .topbar-table.table > tbody > tr:nth-child(2) > td {
    padding-left: 1px !important;
  }

  
  @media screen and (min-width: 992px) and (max-width: 1024px) {

    .topbar-table table {
  
      margin-left: 1% !important;
      font-size: 12px;
      padding: 100px !important;
    }
  
  }

}


@media screen and (min-width: 1200px) and (max-width: 1800px) {

  .metric_button {
    padding: 10px !important;
  }  
}
/* ----------------------  Metrics Component Css Start Here----------------------------- */


/* ----------------------  Metrics Defination Component Css Start Here----------------------------- */

@media screen and (min-width: 320px) and (max-width: 768px) {
  
  h2 {
    
    font-size: 14px !important;
  }

  h3 {
    
    font-size: 12px !important;
  }

  img {

    width: 60%;
    height: auto !important;
  }
  
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  /* Styles for screens with a width of 1024 pixels or more */
   h2 {
    
    font-size: 14px !important;
  }

  h3 {
    
    font-size: 12px !important;
  }

  img {

    width: 60%;
    height: auto !important;
  }
}


/* ----------------------  Metrics Definationy Component Css End Here----------------------------- */


/* ----------------------  Suit Connect Component Css Start Here----------------------------- */

.metric_summary button {
  background: #10202b !important;
  border: 1px solid #4472c4 !important;
  border-radius: 10px !important;
}

.suit-connect {
  text-align: center;
  padding: 45px 0 30px;
  height: 80vh;
  border: 3px solid #4472c4;
  border-radius: 35px;
  margin: 15px 0;
  max-height: 100vh;
}

.suit-bridge img:first-child {

  width: 25%;
  height: auto;
}

.suit-bridge img:nth-child(2) {

  width: 100%;
  height: auto;
}

.suit-connection h4 {
  margin-bottom: 67px; /* Adjust the percentage as needed */
}

.suit-white-box img {
  width: 25%;
  height: auto;
  margin-bottom: 105px;
}

.suit-white-box h4 {
  margin-bottom: 106px;
}
.suit-charge img {
  width: 28%;
  height: auto;
  margin-bottom: 105px;
}

.suit-charge h4 {
  margin-bottom: 101px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  
  
  
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  
  .suit-connection h4 {
    margin-bottom: 12px; /* Adjust the percentage as needed */
  }
  
  .suit-white-box img {
    width: 25%;
    height: auto;
    margin-bottom: 30px;
  }
  
  .suit-white-box h4 {
    margin-bottom: 35px;
  }
  .suit-charge img {
    width: 28%;
    height: auto;
    margin-bottom: 30px;
  }
  
  .suit-charge h4 {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
  
  .suit-connection h4 {
    margin-bottom: 38px; /* Adjust the percentage as needed */
  }
  
  .suit-white-box img {
    width: 25%;
    height: auto;
    margin-bottom: 70px;
  }
  
  .suit-white-box h4 {
    margin-bottom: 67px;
  }
  .suit-charge img {
    width: 28%;
    height: auto;
    margin-bottom: 70px;
  }
  
  .suit-charge h4 {
    margin-bottom: 65px;
  }
}

/* ----------------------  Suit Connect Component Css End Here----------------------------- */





/* ----------------------  Session Summary Component Css Start Here----------------------------- */

.first-session {
  text-align: center;
  padding: 45px 0 30px;
  border: 3px solid #4472c4;
  margin: 15px 0;
  min-height: 168px;
  background-color:#14181b
}
.session_button {
  background: #10202b !important;
  padding:25px 120px !important;
  border: 1px solid #4472c4 !important;
  border-radius: 10px !important;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  /* Styles for screens with a width of 1024 pixels or more */
  .session_button {
    background: #10202b !important;
    padding: 11px 77px !important;
    border: 1px solid #4472c4 !important;
    border-radius: 10px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  /* Styles for screens with a width of 1024 pixels or more */
  .session_button {
    background: #10202b !important;
    padding: 11px 77px !important;
    border: 1px solid #4472c4 !important;
    border-radius: 10px !important;
  }
}

/* ----------------------  Session Summary Component Css End Here----------------------------- */

/* ----------------------  Downloads Component Css Star Here----------------------------- */

.downloads {
 background-color: #fff; 
 text-align: 'center'; 
 margin-right: 10px;
 margin-left: 10px;
 margin-bottom: 10px;
}

.downloads .agree {
  padding:16px 125px;
   margin:7px 0; 
   border:1px solid #000; 
   border-radius: 10px;
   white-space: nowrap;
}

.downloads .upgrade {
  padding:16px 115px;
   margin:7px 0; 
   border:1px solid #000; 
   border-radius: 10px;
   white-space: nowrap;
   color:#7abf6b;
}



@media screen and (min-width: 768px) and (max-width: 1200px) {
  

  .downloads {
    background-color: #fff; 
    text-align: 'center'; 
    margin-right: 10px;
    position: absolute;
    padding-right: 40px !important;
    margin-left: 40px;
    margin-bottom: 20px;
   }
  .downloads .agree {
    padding:16px 0;
    position: absolute;
    left: 1px;
     margin:7px 0; 
     border:1px solid #000; 
     margin-right: 10px;
     border-radius: 10px;
     white-space: nowrap;
  }
  
  .downloads .upgrade {
    padding:15px 0;
     margin:7px 0; 
     position: absolute;
     left: 1px;
     border:1px solid #000; 
     border-radius: 10px;
     white-space: nowrap;
     color:#7abf6b;
  }
  
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
  
  .downloads .agree {
    padding:16px 50px;
     margin:7px 0; 
     border:1px solid #000; 
     border-radius: 10px;
     white-space: nowrap;
  }
  
  .downloads .upgrade {
    padding:16px 40px;
     margin:7px 0; 
     border:1px solid #000; 
     border-radius: 10px;
     white-space: nowrap;
     color:#7abf6b;
  }
  
}





/* ----------------------  Download Component Css End Here----------------------------- */

/* Front end Css Starts Here  */

.navbar-nav li a {

  font-family: 'Alternox-ExLight';
  font-size: 16px;
  color: #6a6d6f !important;
}
.front-navbar li a {

  font-family: 'Alternox';
  font-size: 24px;
}

.navbar-nav li a i {

  font-size: 16px;
}












