.accordion-item {
  border-bottom: 1px solid rgb(var(--metakosmos-blue));
  width: 100%;
  padding-bottom: 15px;

  .accordion-section,
  label {
    cursor: pointer;
    word-break: break-word;
  }

  .accordion-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    font-family: "Alternox-Light";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    overflow: hidden;
    padding: 1rem 15px 0px 15px;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      div {
        width: 8px;
        height: 8px;
        background: rgb(var(--metakosmos-blue));
        box-shadow: 0px 0px 16px 8px rgb(var(--metakosmos-blue), 0.1);
        border-radius: 50%;
        margin-right: 7px;
      }
      .icon {
        width: 25px;
        height: 25px;
        margin-right: 7px;
        border-radius: 10px;
      }
    }
  }

  .rotated {
    transform: rotate(180deg);
  }
}
