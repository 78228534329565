.create-suit-container {
    display: flex !important;
    padding: 94px 40px 40px 40px !important;
    width: 100%;
    justify-content: space-evenly !important;
    height: 100%;

    .component-previewer-options-btn-container {
        width: 45%;
        display: flex;
        flex-direction: column;
    }

    .dual-option-buttons-container {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
    }

    .button {
        font-family: "Alternox";
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 30px 0 30px 0;
        background-color: #14181B;
        border-radius: 16px;
        border: 1px solid rgb(1, 66, 106);
        width: 49%;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }

    .list-image{
        height: 100%;
        aspect-ratio: 1/1;
    }
}


//listitems scss

.list-panel {
    display: flex;
    flex-direction: column;
    width: 15%;
    border-radius: 16px;
    border: 1px solid rgb(1, 66, 106);
    overflow: hidden;
    padding: 15px 0px 15px 0px;

    .list-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        list-style-type: none;
        margin: unset;
        padding: unset;
    }

    .title {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: rgb(255, 255, 255);
        padding: 0px 15px 15px 15px;
    }

    .item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        cursor: pointer;
        background: none;
        border: none;
        height: 148px;
        justify-content: center;
    }

    .list-item-img {
        height: 86%;
        aspect-ratio: 3/2;
    }

    .disabled-item-style {
        opacity: 0.4;
    }

    .card-label {
        font-family: "Alternox-Light";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    .selected-item {
        border-color: rgb(1, 66, 106);
        border-style: solid;
        border-width: 0 4px 0 4px;
    }
}


// component preview sccss

.container-flex {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.component-preview-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    border-radius: 16px;
    border: 1px solid rgb(1, 66, 106);
    overflow: hidden;
    // background-image: url('assets/images/cosmos_wave_background.png');
    background-size: cover;
    background-position: center;
    aspect-ratio: 454/415;

    .carousel-view {
        @extend .container-flex;

        .slider {
            @extend .container-flex;
            overflow: hidden;
        }

        .image {
            aspect-ratio: 1/1;
            width: 70%;
        }

        .right-arrow {
            cursor: pointer;
            user-select: none;
            color: white;
        }

        .left-arrow {
            cursor: pointer;
            transform: rotate(180deg);
            user-select: none;
        }

        .slide {
            opacity: 0;
            transition-duration: 1s ease;
        }

        .slide.active {
            opacity: 1;
            @extend .container-flex;
        }

        .loader {
            width: 15%;
            height: 15%;
        }
    }
}


// component toolbar scss

.preview-card-header {
    display: flex;
    justify-content: space-between;

    
    .component-configure-button {
        padding: 10px 20px 10px 20px;
        width: auto;
        border-radius: 12px;
        font-family: "Alternox";
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background-color: #14181B;
        border: 1px solid rgb(1, 66, 106);
        color: rgb(255, 255, 255);
        cursor: pointer;
    }

    .title {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: rgb(255, 255, 255);
        padding: 0px 15px 15px 15px;
        flex: 0 0 15%;
    }
}


