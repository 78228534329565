.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(var(--black), 0.6);
  display: none;
}

.modal.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-header {
    font-family: 'Alternox-Light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;    
    
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.modal-main {  
  width: 539px;
  height: auto;    
  background: var(--ui-dark-1);  
}

.modal-main {position:relative}
.modal-main:before, .modal-main:after, .modal-main>:first-child:before, .modal-main>:first-child:after {
    position:absolute; 
    content:' ';
    width: 80px; height: 80px;
    border: 5px solid rgb(var(--metakosmos-blue));    
    filter: drop-shadow(0 0 0.4rem rgb(var(--metakosmos-blue)));
}
.modal-main:before {
    top:-4px;
    left:-4px;
    border-width: 5px 0 0 5px;
    border-top-left-radius: 20px;    
}
.modal-main:after {
    top:-4px;
    right:-4px;
    border-width: 5px 5px 0 0;
    border-top-right-radius: 20px;   

}

.modal-main>:first-child:before {
    bottom:-4px;
    right:-4px;
    border-width: 0 5px 5px 0;
    border-bottom-right-radius: 20px;    

}
.modal-main>:first-child:after {
    bottom:-4px;
    left:-4px;
    border-width: 0 0 5px 5px;
    border-bottom-left-radius: 20px;
}