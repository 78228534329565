.dashboard-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 33px;
    padding: 33px;
    height: 100%;
    width: 100%;

    .content-container {
        display: flex;

        button {
            background: none;
            border: none;
            border-radius: 16px;
            height: 50vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            text-align: center;
            cursor: pointer;
            font-family: "Alternox";
            font-weight: 600;
            font-size: 18px;
            color: rgb(255,255,255);

            &[disabled] {
                cursor: not-allowed;
            }

            & > img {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                margin-left: 15px;
            }
        }

        &[disabled] {
            opacity: 0.2;
        }
    }
}


// card css

.card-content {
    background:  #14181B;
    border-radius: 16px;
    position: relative;

    &:before,
    &:after,
    & > :first-child:before,
    & > :first-child:after {
        position: absolute;
        content: " ";
        width: 80px;
        height: 80px;
        border: 5px solid rgb(1, 66, 106);
        filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106));
    }

    &:before {
        top: calc(0px + -4px);
        left: calc(0px + -4px);
        border-width: 5px 0 0 5px;
        border-top-left-radius: 20px;
    }

    &:after {
        top: calc(0px + -4px);
        right: calc(0px + -4px);
        border-width: 5px 5px 0 0;
        border-top-right-radius: 20px;
    }

    & > :first-child:before {
        bottom: calc(0px + -4px);
        right: calc(0px + -4px);
        border-width: 0 5px 5px 0;
        border-bottom-right-radius: 20px;
    }

    & > :first-child:after {
        bottom: calc(0px + -4px);
        left: calc(0px + -4px);
        border-width: 0 0 5px 5px;
        border-bottom-left-radius: 20px;
    }
}
