/* EmailVerified.css */

  
  /* Wrapper to center the card */
  .verify-email-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  
  /* Card design */
  .verify-email-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    padding: 40px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Heading styles */
  .verify-email-card h1 {
    color: #4CAF50;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  /* Paragraph styles */
  .verify-email-card p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Button styles */
  .verify-email-card-btn {
    display: inline-block;
    padding: 12px 30px;
    background-color: #4CAF50;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  /* .verify-email-card:hover {
    background-color: #45a049;
  } */
  
  /* Error message style */
  .verify-email-card p[style="color:red"] {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  /* Animation for fade-in effect */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  