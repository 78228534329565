.app {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.app .header {
  flex: 0 1 auto;
}

.app .content {
  flex: 1 1 auto;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
  height: 100%;
  overflow: hidden;
}

.app .footer {
  flex: 0 1 40px;
  background-color: #282c34;
  color: white;
  text-align: center;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #C9CFD4 rgba(255, 255, 255, 0.3);
  overflow: auto;
}

.scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #C9CFD4;
  box-shadow: inset 0 0 6px rgba(0,0,0, 0.5);
}

.scrollbar::-webkit-scrollbar-button {
  height: 15px;
}

.scrollbar::-webkit-scrollbar-corner {
  width: 0;
}


/* togle code */

.summary-page .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.summary-page .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.summary-page .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.summary-page .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.summary-page input:checked + .slider {
  background-color: #01426A;
}

.summary-page input:focus + .slider {
  box-shadow: 0 0 1px #01426A;
}

.summary-page input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.summary-page .slider.round {
  border-radius: 34px;
}

.summary-page .slider.round:before {
  border-radius: 50%;
}
