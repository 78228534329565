.metric-list-panel {
    flex: 1;
    overflow-x: hidden;
    margin-bottom: 15px;

    & > label {
        font-family: "Alternox";
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        padding: 15px;
        border-bottom: 1px solid rgb(1, 66, 106);
        display: flex;
    }

    .custom-accordion-item {
        border-bottom: unset;
        padding-bottom: unset;
    }

    .custom-accordion-section {
        padding-top: 24px;
        font-family: "Alternox";
        font-weight: lighter;
        font-size: 14px;
    }

    .menu-list li button {
        background: none;
        color: inherit;
        border: none;
        width: 100%;
        outline: inherit;
        font-family: "Alternox";
        font-weight: lighter;
        font-size: 16px;
        line-height: 18px;
        display: flex
    ;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0px 15px;
        margin-top: 24px;
        text-align: left;
        padding-left: 50px;
    }

    .custom-accordion-item {
        border-bottom: 0;
        border-bottom: initial;
        padding-bottom: 0;
        padding-bottom: initial;
        padding-left: 20px;
    }
}
