.summary-page {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: 0.32fr 1.68fr;
    gap: 1%;
    padding: 2%;
    overflow: hidden;
    user-select: none;

    .left-panel {
        grid-row: 1/ -1;
        display: flex;
        flex-direction: column;
        padding: unset;
        overflow: hidden;

        & > label {
            font-family: "Alternox";
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            padding: 15px;
            border-bottom: 1px solid rgb(1, 66, 106);
            display: flex;
        }
    }

    .tile-panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1%;
    }

    .save-button-container {
        padding: 0px 16px;
        font-family: "Alternox";
        button, label {
            font-family: "Alternox";
            font-size: 16px;
            letter-spacing: 0.1em;
            padding: 24px 0 24px 0;
            background-color: rgba(1, 66, 106, 0.2);
            border-radius: 8px;
            border: 1px solid rgb(1, 66, 106);
            width: 100%;
            color: inherit;
            cursor: pointer;
            margin-bottom: 16px;
            text-transform: uppercase;
        }
        label {
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .droptarget {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 32.66%;
        background-image: url("../../assets/images/cosmos_wave_background.png") !important;
        background-size: cover !important;
        background-position: center;
        background: var(--ui-dark-1);
        border: 1px solid rgb(1, 66, 106);
        border-radius: 16px;
        padding: 15px;
    }

    .metric-tile-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .metric-header {
            display: flex;
            justify-content: space-between;

            > label {
                padding: 12px 0px;
                font-family: "Alternox";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
            }

            > img {
                width: 40px;
                height: 40px;
                pointer-events: none;
            }
        }
    }

    .empty-metric-data {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .add-icon {
        img {
            padding: 25%;
            width: 74px;
            height: 74px;
            border-radius: 50%;
            box-shadow: 0px 0px 16px 8px rgb(149 195 85 / 50%);
            pointer-events: none;
        }
    }
}


// accrodain scss code 

.accordion-item {
    border-bottom: 1px solid rgb(1, 66, 106);
    width: 100%;
    padding-bottom: 15px;
    .accordion-section,
    label {
      cursor: pointer;
      word-break: break-word;
    }
  
    .accordion-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      font-size: 14px;
      font-family: 'Alternox' !important;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      overflow: hidden;
      padding: 1rem 15px 0px 15px;
      align-items: center;
  
      > div {
        display: flex;
        align-items: center;
  
        div {
          width: 8px;
          height: 8px;
          background: rgb(1, 66, 106);
          box-shadow: 0px 0px 16px 8px rgb(1, 66, 106, 0.1);
          border-radius: 50%;
          margin-right: 7px;
        }
        .icon {
          width: 25px;
          height: 25px;
          margin-right: 7px;
          border-radius: 10px;
        }
      }
    }
  
    .rotated {
      transform: rotate(180deg);
    }
  }
  
