.tasks-list {
    list-style-type: none;
    padding: unset;
    display: flex;
    flex-direction: column;
    flex: 1;

    & > li {
        margin-bottom: 24px;

        & > button {
            background: none;
            color: white;
            border: none;
            cursor: pointer;
            width: 100%;
            text-align: left;
            font-family: "Alternox";
            font-size: 16px;
            line-height: 18px;
            padding: 0px 15px;
            font-weight: 300;
            opacity: 0.4;

            &[disabled] {
                cursor: not-allowed;
            }
        }

        &.selected {
            border-color: rgb(1, 66, 106);
            border-style: solid;
            border-width: 0px 4px;

            & > button {
                font-weight: 600;
                opacity: 1;
            }
        }

        &.completed {
            & > button {
                opacity: 0.2;
            }
        }
    }
}
