.single-panel {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;

    .value {
        font-family: "Alternox";
        font-weight: 300;
        font-size: 3.5vw;
        text-align: center;
    }
    .unit {
        font-family: "Alternox-Light";
        font-size: 2.5vw;
        font-weight: 200;
        text-align: center;
        padding-top: 47px;
    }
}

.alert {
    color: 197, 53, 44;
}

.emotibit-card{
    cursor: pointer;
}
