/* .custom-modal-style {
    max-width: 890px; 
    height: 650px; 
    margin: auto; 
    border-radius: 12px; 
    overflow: hidden; 
    position: fixed;
    top: 50px;
    right: 400px;
    background-color: #1E2429;
    
  } */
  /* Modal Styles */
.custom-modal-style {
  max-width: 890px !important;
  width: 100% !important;
  min-height: 650px;
  background-color: #1E2429 !important;
  border-radius: 12px !important;
  overflow: hidden;
  right: 490px;
}

.modal-dialog {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 1.75rem auto !important;
  max-width: 890px !important;
}

.custom-modal-style .modal-content {
  background-color: #1E2429 !important;
  border: none !important;
  height: 100%;
  width: 100%;
}

.custom-modal-style .modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem;
}

.custom-modal-style .modal-title {
  color: #fff;
}

.custom-modal-style .modal-body {
  height: calc(100% - 56px);
  overflow-y: auto;
  padding: 1rem;
}

/* Backdrop style */
.modal-backdrop.show {
  opacity: 0.7;
}

/* Animation for modal */
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
  transform: none;
}

.btn-style {
  position: relative;
  left: 50px;
}


/* Responsive adjustments for screen sizes between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .custom-modal-style,
  .modal-dialog {
    max-width: 65% !important;
    margin: 1rem auto !important;
    right: 200px;
  }
}

/* Responsive adjustments for screen sizes between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .custom-modal-style,
  .modal-dialog {
    max-width: 65% !important;
    margin: 1rem auto !important;
    right: 200px;
  }
}

/* Responsive adjustments for screen sizes between 768px and 1024px */
@media (min-width: 1024px) and (max-width: 1440px) {
  .custom-modal-style,
  .modal-dialog {
    max-width: 65% !important;
    margin: 1rem auto !important;
    right: 200px;
  }
}
 