// .session-title {
//     font-family: "Alternox";
//     line-height: 60px;
//     font-size: 35px;
// }

// .session_homepage {
//     position: relative;
//     color: #FFFFFF;
//     span.corner-top-left,
//     span.corner-top-right,
//     span.corner-bottom-left,
//     span.corner-bottom-right {
//       display: block;
//       content: "";
//       width: 70px;
//       height: 70px;
//       position: absolute;
//       background: transparent;
//     }
  
//     span.corner-top-left {
//         top: -4px;
//         left: -4px;
//         border-top: 0.3rem solid #01426A;
//         border-left: 0.3rem solid #01426A;
//         filter: drop-shadow(0 0 0.4rem rgb(var(1, 66, 106)));
//         border-top-left-radius: 5px; 
//     }
  
//     span.corner-top-right {
//       top: -4px;
//       right: -4px;
//       border-top: 0.3rem solid #01426A;
//       border-right: 0.3rem solid #01426A;
//       border-top-right-radius: 5px;
//     }
  
//     span.corner-bottom-left {
//       bottom: -4px;
//       left: -4px;
//       border-bottom: 0.3rem solid #01426A;
//       border-left: 0.3rem solid #01426A;
//       border-bottom-left-radius: 5px;
//     }
  
//     span.corner-bottom-right {
//       bottom: -4px;
//       right: -4px;
//       border-bottom: 0.3rem solid #01426A;
//       border-right: 0.3rem solid #01426A;
//       border-bottom-right-radius: 5px;
//     }
//   }

//   .card-content label {

//     font-family: "Alternox";
//     line-height: 120px;
//     font-size: 28px;
//   }

.session-container {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  height: 90%;

  .session-title {
    color: rgb(255, 255, 255);
    margin-top: 85px;
      font-family: "Alternox";
      line-height: 60px;
      padding: 0px 30px;
      font-size: 35px;
  }

  .session-body {
      display: flex;
      align-items: center;
      padding-bottom: 50px;
      flex: 1;
      justify-content: space-around;
  }

  .session-continue-button {
      font-family: "Alternox";
      padding: 40px 0px;
      width: 30%;
      height: 80px;
      font-size: 14px;
      font-weight: 200;
      color: rgb(255, 255, 255);
      margin: 0 auto;
      background: rgb(1, 66, 106, 0.2);
      border: 1px solid rgb(1, 66, 106);
      border-radius: 16px;
      cursor: pointer;
      display: flex;
      text-transform: uppercase;
      align-items: center;
      justify-content: center;
  }
}


// list menu css
// .list-menu-container .list-menu-content {
//   font-family: "Alternox";
//   font-size: 20px;
//   margin-top: 10px;
//   height: 100%;
// }
.list-menu-container {
  width: 33%;
  text-align: center;
  height: 100% !important;
  padding: 30px;
  font-family: "Alternox";
  .card-title {
      font-size: 12px;
      letter-spacing: 1px;
  }

  .list-menu-content {
      font-family: "Alternox";
      font-size: 20px;
      margin-top: 10px;
      height: 100%;

      main {
          height: 100%;
      }
  }

  .default-label {
      cursor: pointer;
      height: 25%;
      align-items: center;
      justify-content: center;
      font-weight: 200;
      opacity: 0.2;
      display: flex;
      width: 100%;
      background: transparent;
      border: none;
      color: white;
      font-family: "Alternox";

      &:focus {
          opacity: 1;
          outline: none;
      }
  }

  .selected {
      font-weight: 500;
      opacity: 1;
  }
}


// card scss

.card-content {
  background: #14181B !important;
  border-radius: 16px;
  position: relative;

  &:before,
  &:after,
  & > :first-child:before,
  & > :first-child:after {
      position: absolute;
      content: " ";
      width: 80px;
      height: 80px;
      border: 5px solid rgb(1, 66, 106) !important;
      filter: drop-shadow(0 0 0.4rem rgb(1, 66, 106)) !important;
  }

  &:before {
      top: calc(0px + -4px) !important;
      left: calc(0px + -4px) !important;
      border-width: 5px 0 0 5px !important;
      border-top-left-radius: 20px !important;
  }

  &:after {
      top: calc(0px + -4px) !important;
      right: calc(0px + -4px) !important;
      border-width: 5px 5px 0 0 !important;
      border-top-right-radius: 20px !important;
  }

  & > :first-child:before {
      bottom: calc(0px + -4px) !important;
      right: calc(0px + -4px) !important;
      border-width: 0 5px 5px 0 !important;
      border-bottom-right-radius: 20px !important;
  }

  & > :first-child:after {
      bottom: calc(0px + -4px) !important;
      left: calc(0px + -4px) !important;
      border-width: 0 0 5px 5px !important;
      border-bottom-left-radius: 20px !important;
  }
}

.activity-menu::-webkit-scrollbar {
    width: 5px;
}

.activity-menu::-webkit-scrollbar-thumb {
    background-color: #01426A !important;
    border-radius: 6px; /* Border radius of the scrollbar thumb */
}

.activity-menu::-webkit-scrollbar-thumb:hover {
    background-color: #01426A !important;
}

.list-menu-container .disabled {
    opacity: 0.5; /* Dim the whole container to indicate it's disabled */
    pointer-events: none; /* Prevents all click events on this container */
    background-color: #f0f0f0; /* Optional: change background color */
}