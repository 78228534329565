.steps {
    menu {
        list-style-type: none;
        margin: unset;
        padding: 21px 0px 0px 0px;

        li {
            button {
                padding: 0px 12px 16px 12px;
                display: flex;
                background: none;
                border: none;
                align-items: center;
                cursor: pointer;

                &[disabled] {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                label {
                    font-size: 16px;
                    line-height: 19px;
                    color: rgb(var(--primary-text-color));
                    text-align: left;
                    font-family: "Alternox-Light";
                    cursor: pointer;

                    &[disabled] {
                        cursor: not-allowed;
                    }
                }

                & > div {
                    padding-right: 18px;
                    display: flex;

                    img {
                        display: none;
                    }

                    div {
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        border: 1.2px solid rgb(var(--primary-text-color));
                    }
                }
            }
        }

        & .completed {
            & > button {
                label {
                    color: var(--terra);
                }

                & > div {
                    display: flex;

                    img {
                        width: 17px;
                        height: 17px;
                        display: unset;
                    }

                    div {
                        display: none;
                    }
                }
            }
        }
    }

    .fullscreen-view {
        width: 30%;
    }
}
