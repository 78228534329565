.slick-next {
    right: 20px !important;
}

.slick-prev {
    left: 130px !important;
}

.slick-track
{
    position: relative;
    top: 0;
    left: 60% !important;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.body-part-link {
    color: #FFFFFF !important;
    font-size: 16px !important;
}