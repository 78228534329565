
.page-header  {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover; /* Ensures background covers the entire element */
  background-position: center center; /* Center the background image */
}

.container-landing {
  margin-top: 18em;
  z-index: 1000;
}
.login-register button {
  background: rgba(1, 66, 106, 0.2);
    border: 1px solid #01426a;
}
.moving-clouds {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 250.625em; /* Consider adjusting or using repeat-x if the image is seamless */
  height: 43.75em;
  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite;
  background-size: auto 100%; /* Adjust based on your design needs */
  background-repeat: repeat-x; /* Only if your clouds image is seamless and can be repeated */
  }
  @keyframes cloudLoop {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(-50%, 0, 0);
      transform: translate3d(-50%, 0, 0);
    }
  }

  .select-style {
    background-color: rgb(20, 24, 27);
    color: white; /* If you want to keep the text white */
  }
  
  .options {
    background-color: rgb(20, 24, 27);
    color: white; /* If you also want to change the option text color */
  }

  .landing-checkbox {
    position: relative;
}

.landing-checkbox input[type="checkbox"]:checked + label {
  background-color: #14181b;
  border-color: #66bb6a;
}

.landing-checkbox input[type="checkbox"]:checked + label:after {
  border-color: #66bb6a;
}

.landing-checkbox input[type="checkbox"]:checked + label:after {
  border-color: #66bb6a;
}

.landing-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.landing-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 10px;
}
  .landing-checkbox label {
    border: 1px solid #ccc;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
}


.landing-checkbox .error-checkbox {
  border-color: red;
}

.shake-checkbox {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.terms-condition {
    /* Other styles */
    scrollbar-width: thin;
    scrollbar-color: rgba(1, 66, 106, 0.2) #01426a;
}

.terms-condition::-webkit-scrollbar {
    width: 2px; /* Adjust as needed */
}

.terms-condition::-webkit-scrollbar-track {
    background: rgba(1, 66, 106, 0.2);
}

.terms-condition::-webkit-scrollbar-thumb {
    background-color: #01426a;
    border-radius: 5px;
}

