.duration-clock-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 242px;

    .value {
        font-family: "Alternox";
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 55px;
        text-align: center;
        text-shadow: 0px 0px 15px rgb(var(--black));
    }

    .label {
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-shadow: 0px 0px 15px rgb(var(--black));
    }
}
