.task-panel {
    padding: 0px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;

    h3 {
        padding: 0px 15px;
        margin-bottom: 0px;
    }

    & > div {
        padding: 0px 16px;

        button {
            font-family: "Alternox";
            font-size: 16px;
            letter-spacing: 0.1em;
            padding: 24px 0px;
            background-color: rgba(1, 66, 106, 0.2);
            border-radius: 8px;
            border: 1px solid rgb(1, 66, 106);
            width: 100%;
            color: inherit;
            cursor: pointer;
            margin-bottom: 16px;
            text-transform: uppercase;

            &:disabled{
                opacity:0.4;
                cursor:not-allowed
            }
        }
    }

    .new-simulation-button {
        background-color: #14181B;
    }
}
