.chart-container {
    padding: 20px;
    background: #1e2429;
    border-radius: 10px;
    box-shadow: 0px 4px 10px#1e2429;
    border: 1px solid #333;
    max-width: 800px;
    margin: 20px auto;
  }
  
  .chart-container .echarts-for-react div {
    outline: none;
  }
  
  .chart-container:hover {
    box-shadow: 0px 8px 20px rgb(1, 66, 106);
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
  }
  