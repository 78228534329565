.simulation .content-card {
  background: #14181B !important;
  overflow-y: auto;
   max-height: 100%;
   font-size: 14px;
   font-family: 'Alternox-ExLight';
   /* position: fixed;  
   top: 50px;        
   left: 10px;       
   width: 250px; */
}

.simulation .content-card::-webkit-scrollbar {
  width: 3px;
}

.simulation .content-card::-webkit-scrollbar-thumb {
  background-color: #01426A;
  
}

.simulation img {
  width: 100% !important; 
  height: 550px !important; 
  max-width: 450px !important;
}

/* .simulation img {
  width: 100% !important; 
  height: 100vh !important; 
  max-width: 100% !important;
} */

.simulation video {
  width: 100% !important; 
  height: 550px !important; 
  max-width: 450px !important;
}

.simulation iframe {
  width: 100% !important; 
  height: 550px !important; 
  max-width: 450px !important;
  object-fit: cover;
}

.simulation-button {
    background: #101f2a !important;
    /* padding:15px 65px !important; */
    border: 1px solid #01426A;
    border-radius: 10px !important;
    font-family: 'Alternox-ExLight';
    font-size: 12px !important;
    letter-spacing: 2px;
}

.simulation .session-summary {
  font-family: 'Alternox-ExLight';
    font-size: 14px;
}
.simulate-table.table > thead > tr > th,
  .simulate-table.table > tbody > tr > th,
  .simulate-table.table > tfoot > tr > th,
  .simulate-table.table > thead > tr > td,
  .simulate-table.table > tbody > tr > td,
  .simulate-table.table > tfoot > tr > td {
    border: none !important;
    text-align: justify;
    color: #FFFFFF !important;
    font-family: 'Alternox-ExLight';
    font-size: 12px;
    letter-spacing: 1px;
  
  }

  
.model {
    /* position: relative !important;
    top: 80px; */
    width: 100% !important; 
    height: 550px !important; 
    max-width: 450px !important;
  }