/* ResetPassword.css */

  
  
    .reset-password-container {
        max-width: 400px;
        margin: 0 auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        color: white;
        background-color: #212529;
        position: relative;
        top: 200px;
    }
  
  
 .reset-password-container input {
    width: 100%;
    padding: 10px 1px;
    margin: 10px 0;
    font-size: 16px;
  }
  
 .reset-password-container button {
    padding: 10px 20px;
    background-color:  #01426a;
    border:  1px solid #01426a;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .toggle-password-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #007bff;
  }
  
  .toggle-password-icon:hover {
    color: #0056b3;
  }
 
  
 