
#wrapper{
    position: fixed;
	width: 18%;
    z-index: 1000;
	height:300px;
    top: 70%;
    right: -5%; 
    border-radius: 50%;
	margin:10px 10px;	
}

#wrapper a{
  float:right;
	text-align: center;
	color: #fff;
}
#menu{
	position:relative;
	left:50%;
	margin:100px 0 0 -30px;

}

.circle-menu{
	position: relative;
	width: 50px;
	height: 50px;	
	list-style: none;
	margin:0 0 ;
	padding: 0 0;
}
.circle-menu span{
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius:50%;
	background: #01426A;
	box-shadow: 0 0 5px #c6c6c6;
	cursor: pointer;
	z-index:2;
}
.circle-menu span a{
	position: absolute;
	top:10px;
	left:15px;
  color:#fff;
  font-size:35px;
  font-weight:bold;
  line-height:32px;
  margin:0 0;
  padding:0 0;
  
}
.abrir{
	-webkit-animation: rotate_back 0.5s forwards;  
}
.fechar{
	-webkit-animation: rotate 0.5s forwards;  
}
@-webkit-keyframes rotate{
	from{
		-webkit-transform:rotate(0deg);
	}
	to{
		-webkit-transform:rotate(45deg);
	}
}
@-webkit-keyframes rotate_back{
	from{
		-webkit-transform:rotate(45deg);
	}
	to{
		-webkit-transform:rotate(0deg);
	}
}
.circle-menu span:before{
	display: block;
	content: "";
	width:45px;
	height: 45px;
	margin:2.5px 0 0 2.5px;
	border-radius:50%;
	background: #01426A;
	box-shadow:0 0 5px #000;
}
.circle-menu li{
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius:50%;
	background: #14181B;
	z-index: 1;
	cursor: pointer;
}
.circle-menu li p{
	margin:100px 0 0 0;
}

.rotate {
    transform: rotate(45deg);
    transition: transform 0.5s ease-in-out;
  }
  
  .circle-menu.open span a {
    transform: rotate(45deg);
  }
  
  .circle-menu.open li {
    transform: translate(0) !important;
  }

  @keyframes roll {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .circle-menu.open li {
    animation: roll 1s infinite linear;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    #wrapper {
        position: fixed;
        width: 25%;
        z-index: 1000;
        height: 300px;
        top: 30%;
        right: -5%;
        border-radius: 50%;
        margin: 10px 10px;
    }
}
